import styled from "styled-components"
import { sm, md, lg } from "mill/utils/breakpoints"

export const ImageContainer = styled.div`
  background-image: url("https://yarno.imgix.net/static/campaign-invite/seneca-launch.png?auto=format,compress&w=4000");
  background-size: 200%;
  background-position: left 18rem;
  height: 75vh;
  width: 100%;
  flex: 1;

  @media ${sm} {
    background-size: 170%;
  }

  @media ${md} {
    background-size: 160%;
    background-position: left top;
  }

  @media ${lg} {
    background-size: 150%;
  }

  @media (min-width: 70em) {
    background-size: 130%;
  }
`

export const ContentContainer = styled.div`
  margin-top: 8rem;
  max-width: 40rem;

  @media ${lg} {
    margin-left: 12rem;
    margin-top: 10rem;
    max-width: 50rem;
  }
`
